import React, { useEffect, useState } from 'react';

function TaskDashboard() {
  const [tasks, setTasks] = useState([]);
  const [showCreateForm, setShowCreateForm] = useState(false);

  useEffect(() => {
    fetchTasks();
  }, []);

  const fetchTasks = async () => {
    const response = await fetch(`${process.env.REACT_APP_API_URL}/tasks`);
    const data = await response.json();
    setTasks(data);
  };

  const createTask = async (task) => {
    await fetch(`${process.env.REACT_APP_API_URL}/tasks`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(task)
    });
    fetchTasks();
  };

  const handleCreateTask = async (e) => {
    e.preventDefault();
    const formData = new FormData(e.target);
    const newTask = {
      name: formData.get('name'),
      description: formData.get('description'),
      active: formData.get('active') === 'on'
    };
    await createTask(newTask);
    setShowCreateForm(false);
  };

  return (
    <div>
      <h1>Task Dashboard</h1>

      <table>
        <thead>
          <tr>
            <th>Name</th>
            <th>Description</th>
            <th>Active</th>
          </tr>
        </thead>
        <tbody>
          {tasks.map((task) => (
            <tr key={task.id}>
              <td>{task.name}</td>
              <td>{task.description}</td>
              <td>{task.active ? 'Active' : 'Inactive'}</td>
            </tr>
          ))}
        </tbody>
      </table>

      <button onClick={() => setShowCreateForm(true)}>Create Task</button>

      {showCreateForm && (
        <form onSubmit={handleCreateTask}>
          <div>
            <label>Name:</label>
            <input type="text" name="name" required />
          </div>
          <div>
            <label>Description:</label>
            <input type="text" name="description" />
          </div>
          <div>
            <label>Active:</label>
            <input type="checkbox" name="active" />
          </div>
          <button type="submit">Submit</button>
        </form>
      )}
    </div>
  );
}

export default TaskDashboard;
